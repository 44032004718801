// Import all of Bootstrap's CSS
/*
$btn-border-radius-lg: 1.5rem;
$font-family-sans-serif: Montserrat, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$gray-100: #efeded;
$gray-800: #002e6d;
$input-border-radius: 0rem;
$gray-600: #adb5bd;
$orange: #f18d1e;
$yellow: #f18d1e;
$blue: #0081c3;
*/

@import "bootstrap/scss/bootstrap";


.action-click {
    text-decoration: none;
}

.action-click:hover {
    opacity: 0.9;
}

a,
a:focus,
button,
button:focus {
    outline: none;
}

.clicker {
    cursor: pointer;
}